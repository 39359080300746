import React from 'react';
import {useEffect,useState} from 'react'
import axios from 'axios'
import { View, Text, Image, TextInput, TouchableOpacity } from 'react-native';

//import { Dropdown } from 'react-native-element-dropdown';


const UpdateCustomer = ({ route }) => {
  const { id } = route.params;
  console.log(id);
  const [customer, setCustomer]= useState({
    nombre: '',
    direccion: '',
    correo: '',
    descuento: '',
    telefono: '',});
  

  useEffect(()=>{
    const fetchCustomer= async ()=>{
        try{
          const res=await axios.get(`https://tonitaswebapp.onrender.com/customer/${id}`);
          console.log(res.data);
          setCustomer(res.data);
        }catch(err){
          console.log(err);
        }
    }
    fetchCustomer()
  },[id]);

  const handleUpdate= async (id)=>{
    try{
      await axios.patch("https://tonitaswebapp.onrender.com/customer/"+id,customer);
      //window.location.reload()
    }catch(err){
      console.log(err);
    }
  }

  const handleChange = (name, value) => {
    setCustomer(prev => ({
      ...prev,
      [name]: value,
    }));
  };

 
  
  return (
    <View style={styles.container}>
      <Image
        source={{uri: 'https://s3-media0.fl.yelpcdn.com/bphoto/us1IcgvwxbectoUVxSxOHQ/o.jpg'}}
        style={styles.background}
      />
      <View style={styles.logoContainer}>
        <Image
          source={{uri: 'http://tonitascarpetcleaning.com/wp-content/uploads/2019/05/Tonitas7.png'}}
          style={styles.logo}
        />
      </View>
      <View style={styles.formContainer}>
        <Text style={styles.title}>Customer</Text>
        <View style={styles.card}>
          <View style={styles.inputContainer}>
            <Text style={styles.label}>Name</Text>
            <TextInput
              style={styles.input}
              value={customer.nombre}
              onChangeText={(value) => handleChange('nombre', value)}
              placeholder="Name"
              placeholderTextColor="#999"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.label}>Address</Text>
            <TextInput
              style={styles.input}
              value={customer.direccion}
              onChangeText={(value) => handleChange('direccion', value)}
              placeholder="Address"
              placeholderTextColor="#999"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.label}>Email</Text>
            <TextInput
              style={styles.input}
              value={customer.correo}
              onChangeText={(value) => handleChange('correo', value)}
              placeholder="Email"
              placeholderTextColor="#999"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.label}>Discount</Text>
            <TextInput
              style={styles.input}
              value={customer.descuento}
              onChangeText={(value) => handleChange('descuento', value)}
              placeholder="Discount"
              placeholderTextColor="#999"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.label}>Phone</Text>
            <TextInput
              style={styles.input}
              value={customer.telefono}
              onChangeText={(value) => handleChange('telefono', value)}
              placeholder="Phone"
              placeholderTextColor="#999"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.label}>Status</Text>
            
          </View>
          
          <TouchableOpacity style={styles.button} onPress={() => handleUpdate(customer.idcliente)}>
            <Text style={styles.buttonText}>Update</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

export default UpdateCustomer;

const styles = {
  container: {
    flex: 1,
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  logoContainer: {
    alignItems: 'center',
    marginTop: 120,
  },
  logo: {
    width: 120,
    height: 120,
    borderRadius:60,
    resizeMode: 'contain',
  },

    formContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 24,
      color: '#fff',
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      width: '80%',
      backgroundColor: '#fff',
      borderRadius: 4,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      padding: 20,
      marginBottom: 20,
    },
    inputContainer: {
      marginBottom: 20,
    },
    label: {
      fontSize: 16,
      color: '#333',
    },
    input: {
      height: 40,
      borderRadius:6,
      borderWidth: 1,
      borderColor: '#ddd',
      color: '#333',
      paddingLeft:10,
    },
    button: {
      width: '100%',
      height: 40,
      backgroundColor: '#00BFFF',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
    },
    buttonText: {
      color: '#fff',
      fontSize: 16,
    },
    dropdown: {
      height: 50,
      borderColor: 'gray',
      borderWidth: 0.5,
      borderRadius: 8,
      paddingHorizontal: 8,
    },
    icon: {
      marginRight: 5,
    },
    placeholderStyle: {
      fontSize: 16,
    },
    selectedTextStyle: {
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },

  };
  
                                            