import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Login from './Login'; // Your login screen component
import MainPage from './MainPage'; // The component for the screen you navigate to after login
import PriceList from './PriceList';
import Customers from './pages/Customers';
import CustomersSearch from './pages/CustomersSearch';
import UpdateCustomer from './pages/UpdateCustomer';
import Invoices from './pages/Invoices';
import Invoice from './pages/Invoice';


const Stack = createStackNavigator();

function AppNavigator() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Home" component={MainPage} />
        <Stack.Screen name="PriceList" component={PriceList} />
        <Stack.Screen name="Customersv2" component={Customers}/>
        <Stack.Screen name="UpdateCustomer" component={UpdateCustomer}/>
        <Stack.Screen name="Invoices" component={Invoices}/>
        <Stack.Screen name="Invoice" component={Invoice}/>
        <Stack.Screen name="CustomersSearch" component={CustomersSearch}></Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;
