import React from 'react'
import {useEffect, useState} from 'react'
import axios from 'axios'
import { useNavigation } from '@react-navigation/native';

const CustomersSearch= ({ onSelectCustomer }) => {
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = useState('');
  const navigation = useNavigation();
  



  useEffect(()=>{
    const fetchAllCustomers= async ()=>{
        try{
          const res=await axios.get("https://tonitaswebapp.onrender.com/customers");
          // console.log(res)
          setCustomers(res.data);
        }catch(err){
          console.log(err);
        }
    }
    fetchAllCustomers()
  },[]);

  const filteredCustomers = customers.filter(customer =>
    customer.nombre.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelectCustomer = (customer) => {
    navigation.navigate('Invoices', { selectedCustomer: customer });
  };

  

  

  return (
    <div>
      <input
        type="text"
        placeholder="Search by name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <h1>Customers</h1>
      <div className='customers'>
      <table border="1" cellPadding="8" cellSpacing="0">
        <thead>
         <tr>
            <td><b>idcliente</b></td>
            <td><b>nombre</b></td>
            <td><b>direccion</b></td>
            <td><b>telefono</b></td>
            <td></td>
            <td></td>
        </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((customer) => (
            <tr key={customer.idcliente}>
              <td>{customer.nombre}</td>
              <td>{customer.direccion}</td>
              <td>{customer.telefono}</td>
              <td><button onClick={() => handleSelectCustomer(customer)}>Select</button></td>
            </tr>
          ))}
        </tbody>

       
      
      </table>
      </div>
      
    </div>
  );
}

export default CustomersSearch