import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';




function downloadFile(url) {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop(); // Extract filename from URL
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function DownloadButton({ url,fileName }) {
  return (
    
        <View style={styles.card}>
          <TouchableOpacity style={styles.button} onPress={() => downloadFile(url)}>
            <Text style={styles.buttonText}>{fileName}</Text>
          </TouchableOpacity>
        </View>
        
     
  );
}

function PriceList() {
  
  return (
    <View style={styles.container}>
      <Image
        source={{uri: 'https://s3-media0.fl.yelpcdn.com/bphoto/us1IcgvwxbectoUVxSxOHQ/o.jpg'}}
        style={styles.background}
      />
      <View style={styles.logoContainer}>
        <Image
          source={{uri: 'http://tonitascarpetcleaning.com/wp-content/uploads/2019/05/Tonitas7.png'}}
          style={styles.logo}
        />
      </View>
      <View style={styles.formContainer}>
        <Text style={styles.title}>Price List</Text>
          <DownloadButton 
              url="https://drive.google.com/uc?id=1447aXF6IDZ13Q-7vEU8ElmuQdAfCHBfX&export=download"
              fileName="Customers PriceList"
          />
          <DownloadButton 
              url="https://drive.google.com/uc?id=1uixJBgblHsyh_CWe_xgxOU_yiv9bc7Ef&export=download"
              fileName="Properties PriceList"
          />
      </View>
    </View>
  );
}

export default PriceList;

const styles = {
  container: {
    flex: 1,
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  logoContainer: {
    alignItems: 'center',
    marginTop: 120,
  },
  logo: {
    width: 120,
    height: 120,
    borderRadius:60,
    resizeMode: 'contain',
  },

    formContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 24,
      color: '#fff',
      marginBottom: 20,
      marginTop: 20,
    },
    card: {
      width: '80%',
      backgroundColor: '#fff',
      borderRadius: 4,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      padding: 20,
      marginBottom: 20,
    },
    inputContainer: {
      marginBottom: 20,
    },
    label: {
      fontSize: 16,
      color: '#333',
    },
    input: {
      height: 40,
      borderRadius:6,
      borderWidth: 1,
      borderColor: '#ddd',
      color: '#333',
      paddingLeft:10,
    },
    button: {
      width: '100%',
      height: 40,
      backgroundColor: '#00BFFF',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
    },
    buttonText: {
      color: '#fff',
      fontSize: 16,
    },
  };
  
                                            