import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image,PDFDownloadLink } from '@react-pdf/renderer';
import {View as rnView, Dimensions } from 'react-native';
// Import your logo here, if it's a local file:
import TonitasLogo from '../images/TonitasLogo.png'


// Register a font if you need to match the font from the invoice
// Font.register({ family: 'Oswald', src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf' });

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: width,
    height: height,
  },
  pdfViewer: {
    flex: 1,
    width: width, // Full width of the screen
    height: height, // Full height of the screen
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 40,
  },
  logo: {
    width: 74, // Adjust according to your logo's aspect ratio
    height: 66,
    marginBottom: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerLeft: {
    width: '10%',
  },
  headerRight: {
    width: '50%',
    textAlign: 'right',
  },
  headerCenter: {
    width: '100%',
    textAlign: 'center',
    alignItems:'center',
  },
  clientDetails: {
    marginBottom: 20,
  },
  clientDetailText: {
    fontSize: 12,
  },
  invoiceNumberDate: {
    fontSize: 12,
    textAlign: 'right',
    marginRight: 10, // Adjust as necessary to align with your layout
  },
  itemsHeader: {
    flexDirection: 'row',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: 5,
    fontSize:13,
  },
  itemRow: {
    flexDirection: 'row',
    textAlign: 'left',
    paddingBottom: 5,
    fontSize:12,
  },
  qtyColumn: {
    width: '10%',
  },
  descriptionColumn: {
    width: '70%',
    paddingRight: 10, // Adjust the padding as needed
  },
  amountColumn: {
    width: '20%',
    textAlign: 'right',
  },
  totalContainer: {
    flexDirection: 'row',
    textAlign: 'right',
    marginTop: 180,
    paddingTop: 10,
    borderTopWidth: 2,
  },
  totalLabel: {
    width: '80%',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold',
  },
  totalValue: {
    width: '20%',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  footer: {
    marginTop: 120,
    textAlign: 'center',
  },
});

const MyDocument = ({ businessInfo, clientDetails, lineItems }) => {
  const formatCurrency = (amount) => `$${Number(amount).toFixed(2)}`;

  const totalAmount = lineItems.reduce((total, item) => total + Number(item.amount), 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Image source={TonitasLogo} style={styles.logo} />
          </View>
          <View style={styles.headerCenter}>
            <Text>Tonita's Carpet Cleaning</Text>
            <Text>P.O.BOX 9396</Text>
            <Text>SAN JOSE, CA 95157</Text>
            <Text>408-8191125</Text>
          </View>
        </View>
        <View>
            <Text style={styles.invoiceNumberDate}>INVOICE #{clientDetails.invoiceNumber}</Text>
            <Text style={styles.invoiceNumberDate}>DATE: {clientDetails.date}</Text>
          </View>

        <View style={styles.clientDetails}>
          <Text style={styles.clientDetailText}>Name: {clientDetails.name}</Text>
          <Text style={styles.clientDetailText}>Address: {clientDetails.address}</Text>
          <Text style={styles.clientDetailText}>Phone: {clientDetails.phone}</Text>
        </View>

        <View style={styles.itemsHeader}>
          <Text style={styles.qtyColumn}>QTY</Text>
          <Text style={styles.descriptionColumn}>DESCRIPTION</Text>
          <Text style={styles.amountColumn}>AMOUNT</Text>
        </View>

        {lineItems.map((item, index) => (
          <View key={index} style={styles.itemRow}>
            <Text style={styles.qtyColumn}>{item.quantity}</Text>
            <Text style={styles.descriptionColumn}>{item.description}</Text>
            <Text style={styles.amountColumn}>{formatCurrency(item.amount)}</Text>
          </View>
        ))}

        <View style={styles.totalContainer}>
          <Text style={styles.totalLabel}>TOTAL</Text>
          <Text style={styles.totalValue}>{formatCurrency(totalAmount)}</Text>
        </View>

        <View style={styles.footer}>
          <Text>All claims and returned goods MUST be accompanied by this bill.</Text>
          <Text>THANK YOU</Text>
        </View>
      </Page>
    </Document>
  );
};

const Invoice = ({ route }) => {
  const { businessInfo, clientDetails, lineItems } = route.params;
  
  return (
    <rnView style={styles.container}>
      <PDFViewer style={styles.pdfViewer}>
        <MyDocument
          businessInfo={businessInfo}
          clientDetails={clientDetails}
          lineItems={lineItems}
        />
      </PDFViewer>
      <PDFDownloadLink
        document={<MyDocument businessInfo={businessInfo} clientDetails={clientDetails} lineItems={lineItems} />}
        fileName="invoice.pdf"
        style={styles.downloadLink}
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download Invoice'
        }
      </PDFDownloadLink>
    </rnView>
  );
};

export default Invoice;
