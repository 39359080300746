import React, { useState,useEffect } from 'react';
import { View, TextInput, Button, Text, ScrollView,Alert } from 'react-native';
import { useNavigation,useRoute } from '@react-navigation/native';
import axios from 'axios'
const Invoices = () => {
  const navigation = useNavigation();
  const route = useRoute();

  const [businessInfo] = useState({
    name: "TONITA'S CARPET CLEANING",
    address: 'P.O.BOX 9396',
    city: 'SAN JOSE, CA 95157',
    phone: '408-8191125'
  });

  // Helper function to get the current date in 'mm/dd/yyyy' format
  const getCurrentDateFormatted = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const generateInvoiceNumber = () => {
    const now = new Date();
    const year = String(now.getFullYear()).slice(-2); // Last two digits of the year
    const month = String(now.getMonth() + 1); // Month is 0-based
    const day = String(now.getDate());
    const hours = String(now.getHours()); // 24-hour format
    const minutes = String(now.getMinutes());
    const seconds = String(now.getSeconds());
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  const [clientDetails, setClientDetails] = useState({
    customerid:'',
    name: '',
    address: '',
    phone: '',
    invoiceNumber: '',
    date: ''
  });

  const [lineItems, setLineItems] = useState([
    { description: '', quantity: '', amount: '' }
  ]);

  useEffect(() => {
    if (route.params?.selectedCustomer) {
      const { idcliente,nombre, direccion, telefono } = route.params.selectedCustomer;
      setClientDetails(prevDetails => ({
        ...prevDetails,
        customerid: idcliente,
        name: nombre,
        address: direccion,
        phone: telefono,
        date: getCurrentDateFormatted(),
        invoiceNumber: generateInvoiceNumber()
      }));
    }
  }, [route.params]);

  const handleClientChange = (name, value) => {
    setClientDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleLineItemChange = (index, name, value) => {
    const newLineItems = lineItems.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setLineItems(newLineItems);
  };

  const addLineItem = () => {
    setLineItems([...lineItems, { description: '', quantity: '', amount: '' }]);
  };

  const removeLineItem = (index) => {
    setLineItems(lineItems.filter((_, i) => i !== index));
  };

  const goToInvoiceScreen = async () => {
    try {
      const response = await axios.post("https://tonitaswebapp.onrender.com/invoice", {
        invoiceNumber: clientDetails.invoiceNumber,
        date: clientDetails.date,
        customerId: clientDetails.customerid, // Replace with actual customer ID
        businessInfo,
        lineItems
      });
      console.info(response);
      console.info('It worked!');
      //if (response.status === 201) {
        console.info('It worked!');
        Alert.alert('Success', 'Invoice stored successfully!');
        navigation.navigate('Invoice', { businessInfo, clientDetails, lineItems });
      //} else {
      //  console.error('Something happened');
      //  Alert.alert('Error', 'Failed to store invoice');
      //}
    } catch (error) {
      console.error('Error:', error);
      Alert.alert('Error', 'An error occurred while storing the invoice');
    }
  };

  return (
    <ScrollView>
      <View style={{ padding: 16 }}>
      <Button title="Find Customer" onPress={() => navigation.navigate('CustomersSearch')} />
        <Text>Client Details</Text>
        <TextInput
          placeholder="Client's Name"
          onChangeText={(text) => handleClientChange('name', text)}
          value={clientDetails.name}
          style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
        />
        <TextInput
          placeholder="Client's Address"
          onChangeText={(text) => handleClientChange('address', text)}
          value={clientDetails.address}
          style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
        />
        <TextInput
          placeholder="Client's Phone"
          onChangeText={(text) => handleClientChange('phone', text)}
          value={clientDetails.phone}
          style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
        />
        <TextInput
          placeholder="Invoice Number"
          onChangeText={(text) => handleClientChange('invoiceNumber', text)}
          value={clientDetails.invoiceNumber}
          style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
        />
        <TextInput
          placeholder="Date"
          onChangeText={(text) => handleClientChange('date', text)}
          value={clientDetails.date}
          style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
        />
        
        <Text>Line Items</Text>
        {lineItems.map((item, index) => (
          <View key={index}>
            <TextInput
              placeholder="Description"
              onChangeText={(text) => handleLineItemChange(index, 'description', text)}
              value={item.description}
              style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
            />
            <TextInput
              placeholder="Quantity"
              onChangeText={(text) => handleLineItemChange(index, 'quantity', text)}
              value={item.quantity}
              style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
            />
            <TextInput
              placeholder="Amount"
              onChangeText={(text) => handleLineItemChange(index, 'amount', text)}
              value={item.amount}
              style={{ height: 40, borderColor: 'gray', borderWidth: 1, marginBottom: 10 }}
            />
            <Button title="Remove Line Item" onPress={() => removeLineItem(index)} />
          </View>
        ))}
        <Button title="Add Line Item" onPress={addLineItem} />
        <Button title="Create Invoice PDF" onPress={goToInvoiceScreen} />
      </View>
    </ScrollView>
  );
};

export default Invoices;
