import React from "react";
import AppNavigator from "./AppNavigator";



function App() {
    

    return (
        <div>
            <AppNavigator/>
        </div>
      
  );
}

export default App;