import React from 'react'
import {useEffect, useState} from 'react'
import axios from 'axios'
import { useNavigation } from '@react-navigation/native';

const Customers=()=> {
  const [customers, setCustomers]= useState([]);
  const navigation = useNavigation();

  useEffect(()=>{
    const fetchAllCustomers= async ()=>{
        try{
          const res=await axios.get("https://tonitaswebapp.onrender.com/customers");
          // console.log(res)
          setCustomers(res.data);
        }catch(err){
          console.log(err);
        }
    }
    fetchAllCustomers()
  },[]);

  const handleDelete= async (id)=>{
    try{
      await axios.delete("https://tonitaswebapp.onrender.com/customer/"+id);
      window.location.reload()
    }catch(err){
      console.log(err);
    }
  }

  return (
    <div>
      <h1>Customers</h1>
      <div className='customers'>
        <table>
         <tr>
            <td><b>idcliente</b></td>
            <td><b>nombre</b></td>
            <td><b>direccion</b></td>
            <td><b>telefono</b></td>
            <td></td>
            <td></td>
        </tr>   
        {customers.map(customer=>(
          //<div className="customer" key={customer.idcliente}>
            <tr>
            <td>{customer.idcliente}</td>
            <td>{customer.nombre}</td>
            <td>{customer.direccion}</td>
            <td>{customer.telefono}</td>
           
             <td>
             <button className='delete' onClick={()=>handleDelete(customer.idcliente)}>Delete</button></td>
             <td><button className='update' onClick={() => navigation.navigate('UpdateCustomer',{id:customer.idcliente})}>Update</button></td>
             </tr>
        //</div>
        ))}

        <button>
        Agregar
      </button>
      
      </table>
      </div>
      
    </div>
  );
}

export default Customers